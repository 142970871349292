<template>
<div id="page-sucursales">

    <v-container fluid fill-height>
        <v-row align-center justify-center>
            <v-col>
                <base-material-card color="primary" icon="library_books" title="Limpiar Tabla" class="elevation-1 px-5 py-3">

                    <v-card-text>
                        <v-row>
                            <v-col sm="3" md="3" lg="3">
                                <v-text-field label="Tabla" v-model="tabla"></v-text-field>
                            </v-col>
                            <v-col sm="3" md="3" lg="3">
                                <v-btn color="success" @click.native="consultar()">
                                    <v-icon>done</v-icon> Consultar

                                </v-btn>
                            </v-col>
                        </v-row>

                        <p />

                        Encontrados: {{registros.items.length}}
                        <p />
                        <v-btn v-if="registros.items.length> 0" dark color="red" @click.native="borrar()">
                            <v-icon>delete</v-icon> Borrar {{ cuenta }}
                            
                        </v-btn>
                      
                    </v-card-text>
                </base-material-card>
            </v-col>
        </v-row>
    </v-container>

</div>
</template>

<script>
import PaginacionComponent from '@/components/PaginacionComponent.vue';

export default {

    data() {
        return {
            tabla: "",
            cuenta:0,
            registros: {
                selected: [],
                items: []
            },

        };
    },
    methods: {


        delete: function (_id, _rev) {
          let self = this;
            window.axios
                .delete(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + _id + "?rev=" + _rev)
                .then(response => {
                    console.log("Eliminado: " + _id);
                    self.cuenta +=1;
                })
                .catch(error => {
                    console.log("Error: " + _id);
                    self.cuenta +=1;
                });
        },
        borrar: function () {
          window.dialogLoader.show('Espere un momento por favor..');
          let self = this;
          this.registros.items.forEach(function(x){
            self.delete(x._id, x._rev);
          });
          window.dialogLoader.hide();
        },
        consultar: function () {
          this.cuenta =0;
            window.dialogLoader.show('Espere un momento por favor..');

            let data = {
                "selector": {
                    "type": this.tabla
                },
                "fields": ["_id", "_rev"],
                "use_index": "b8a585e5960e3dc7ff5750a94583c66cb3efa22e",
                "limit": 5000,
            };

            window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
                .then(response => {
                    if (response.data.docs.length > 0)
                        this.registros.items = response.data.docs;
                    else
                        this.registros.items = [];
                    window.dialogLoader.hide();
                })
                .catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener las sucursales.",
                        footer: ""
                    });
                    window.dialogLoader.hide();
                });
        },

    }
};
</script>
